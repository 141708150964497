import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Success.module.scss";

//icons
import XIcon from "../../assets/icons/xmark.svg";
import MailIcon from "../../assets/icons/mail-star.svg";

const Success = ({ setShowSuccess }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.success}>
      <div
        onClick={() => setShowSuccess(false)}
        className={styles.success__cancel}
      >
        <img src={XIcon} alt="cancel icon" />
      </div>

      <div className={styles.success__main}>
        <div className={styles.success__main__icon}>
          <img src={MailIcon} alt="mail" />
        </div>
        <h3 className={styles.success__main__heading}>
          Preference Successful Saved
        </h3>
        <p className={styles.success__main__desc}>
          We will tell you when it gets better. Thank you
        </p>

        <div onClick={() => navigate("/")} className={styles.success__main__buttons}>
          <button className={styles.success__main__submit}>Back to Rates</button>

          {/* <button
            onClick={() => navigate("/")}
            className={styles.success__main__submit}
          >
            See Rates
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Success;
