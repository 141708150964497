import React, { useEffect } from "react";
import styles from "./Rates.module.scss";

//components
import Rate from "../Rate/Rate";

//images
import HourGlass from "../../assets/images/icons8-hour-glass-64.png";

const Rates = ({ isLoading, rates }) => {
  useEffect(() => {
    console.log(rates, "from rates page");
  }, [rates]);

  return (
    <div className={styles.rates}>
      <div className={styles.rates__heading}>
        <h3>Platform</h3>
        <h3>Currency Pair</h3>
        <h3>
          <span>Rate</span>
        </h3>
      </div>

      <div className={styles.rates__items}>
        {isLoading ? (
          <div className={styles.rates__items__loading}>
            <img src={HourGlass} alt="hour glass" />
            <p>Loading Rates</p>
          </div>
        ) : (
          rates && rates?.map((rate) => <Rate rate={rate} />)
        )}
      </div>
    </div>
  );
};

export default Rates;
