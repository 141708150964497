import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NavBar.module.scss";

//images and icons
import Logo from "../../assets/images/logo.svg";
import MenuIcon from "../../assets/icons/menu.svg";

const NavBar = ({ active }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.navBar}>
      <div className={styles.navBar__main}>
        <div onClick={() => navigate("/")} className={styles.navBar__logo}>
          <img src={Logo} alt="logo" />
          <h2>
            FX<b>RATE</b>
          </h2>
        </div>
        <div className={styles.navBar__menu}>
          <img src={MenuIcon} alt="menu" />
        </div>
      </div>

      <div className={styles.navBar__actions}>
        <div className={styles.navBar__actions__content}>
          <div
            onClick={() => navigate("/")}
            className={
              active === "all"
                ? `${styles.navBar__action} ${styles.navBar__action__active}`
                : `${styles.navBar__action}`
            }
          >
            <h3>All Rates</h3>
          </div>

          <div
            onClick={() => navigate("/convert")}
            className={
              active === "convert"
                ? `${styles.navBar__action} ${styles.navBar__action__active}`
                : `${styles.navBar__action}`
            }
          >
            <h3>Convert Rates</h3>
          </div>

          <div
            onClick={() => navigate("/crypto")}
            className={
              active === "crypto"
                ? `${styles.navBar__action} ${styles.navBar__action__active}`
                : `${styles.navBar__action}`
            }
          >
            <h3>Crypto</h3>
          </div>

          <div
            onClick={() => navigate("/central-banks")}
            className={
              active === "central-banks"
                ? `${styles.navBar__action} ${styles.navBar__action__active}`
                : `${styles.navBar__action}`
            }
          >
            <h3>Central Banks</h3>
          </div>

          <div
            onClick={() => navigate("/providers")}
            className={
              active === "providers"
                ? `${styles.navBar__action} ${styles.navBar__action__active}`
                : `${styles.navBar__action}`
            }
          >
            <h3>Providers</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
