import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getRates } from "../../services";
import getLogoAndTag from "../../helpers/getLogoAndTag";
import styles from "./Providers.module.scss";

//components
import NavBar from "../../components/NavBar/NavBar";
import Rates from "../../components/Rates/Rates";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import GetNotified from "../../components/GetNotified/GetNotified";

//images
import Binance from "../../assets/platforms/Binance.svg";
import Rise from "../../assets/platforms/rise.svg";
import Wise from "../../assets/platforms/Wise.svg";
import SendWave from "../../assets/platforms/sendwave.svg";

const Providers = () => {
  
  useEffect(() => {
    document.title = `Providers | Daily Exchange Rates | FXRates Africa`;
  }, []);
  
  const { isLoading, data } = useQuery(["get rates"], getRates, {
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (error) => {
      console.log({ error });

      toast.error(error?.response?.data?.message ?? "An error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    },
  });

  return (
    <div className={styles.providers}>
      <NavBar active="providers" />

      <div className={styles.providers__intro}>
        <h1 className={styles.providers__intro__heading}>All Providers</h1>

        <p className={styles.providers__intro__desc}>
          Easily get and compare exchange rates across remittance platforms
          before you send money home
        </p>
      </div>

      <div className={styles.providers__providers}>
        {data &&
          data?.data?.map((platform) => (
            <div className={styles.providers__provider}>
              <img
                src={getLogoAndTag(platform?.name)?.logo ?? platform?.logo}
                alt="provider"
              />
            </div>
          ))}
      </div>

      <div className={styles.providers__disclaimer}>
        <p>
          Disclaimer: The information provided on this website is for
          informational purposes only and should not be considered financial
          advice. The exchange rates displayed are based on information that we
          have gathered from various financial institutions and other sources.
          We strive to ensure that the information is accurate and updated
          regularly, but we cannot guarantee the accuracy, reliability, or
          completeness of the data at all times. Exchange rates can fluctuate
          rapidly, and the rate that you receive from a financial institution
          may be different from what is displayed on our website. Before making
          any financial decisions or currency exchanges, we recommend that you
          consult with a financial advisor or directly with the financial
          institution. We are not liable for any losses or damages, direct or
          indirect, that you may incur as a result of using the information
          provided on this website
        </p>
      </div>
    </div>
  );
};

export default Providers;
