import React from "react";
import styles from "./Conversions.module.scss";

//components
import Conversion from "../Conversion/Conversion";

//images
import HourGlass from "../../assets/images/icons8-hour-glass-64.png";
import Logo from "../../assets/images/logo.svg";

const Conversions = ({ isLoading, matches, from, to, amount }) => {
  return (
    <div className={styles.conversions}>
      <div className={styles.conversions__heading}>
        <h3>Platform</h3>
        <h3>Rate</h3>
        <h3>Receiver Gets</h3>
      </div>

      {isLoading ? (
        <div className={styles.conversions__items__loading}>
          <img src={HourGlass} alt="hour glass" />
          <p>Loading Rates</p>
        </div>
      ) : matches?.length > 0 && amount ? (
        <div className={styles.conversions__items}>
          {matches?.map((rate) => (
            <Conversion rate={rate} from={from} to={to} amount={amount} />
          ))}
        </div>
      ) : (
        <div className={styles.conversions__notFound}>
          <div className={styles.conversions__notFound__logo}>
            <img src={Logo} alt="logo" />
            <h2>
              FX<b>RATE</b>
            </h2>
          </div>
          <p>
            Enter the amount you want to convert and a valid currency pair above
            to see best rates with supported platforms
          </p>
        </div>
      )}
    </div>
  );
};

export default Conversions;
