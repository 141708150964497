import React from "react";
import { useNavigate } from "react-router-dom";
import { CaretRight, CaretLeft } from "phosphor-react";
import styles from "./Pagination.module.scss";

const Pagination = ({ base, page, totalPage }) => {
  const navigate = useNavigate();
  return (
    <div className={styles?.pagination}>
      <div
        onClick={() => (page === 1 ? null : navigate(`/${base}/${page - 1}`))}
        className={`${styles.pagination__page}`}
      >
        <CaretLeft size={23} color="#1e1e1e" />
      </div>
      {[...Array(totalPage).keys()]?.map((item, index) => (
        <div
          onClick={() => navigate(`/${base}/${index + 1}`)}
          className={
            page === index + 1
              ? `${styles.pagination__page} ${styles.pagination__page__active}`
              : `${styles.pagination__page} ${styles.pagination__page__other}`
          }
        >
          <p>{index + 1}</p>
        </div>
      ))}
      <div
        onClick={() =>
          page === totalPage ? null : navigate(`/${base}/${page + 1}`)
        }
        className={`${styles.pagination__page}`}
      >
        <CaretRight size={23} color="#1e1e1e" />
      </div>
    </div>
  );
};

export default Pagination;
