import React, { useState, useRef, useEffect } from "react";
import styles from "./Select.module.scss";

//helpers
import getIcon from '../../helpers/getIcon'

const Select = ({ style, icon, label }) => {


  return (
    <div className={styles.select}>
      <div className={styles.select__selected} style={style}>
        <img className={styles.select__selected__icon} src={getIcon(label)} alt="icon" />
        <h4>{label}</h4>
        {/* <img
          className={styles.select__selected__arrow}
          src={ArrowDown}
          alt="arrow down"
        /> */}
      </div>
    </div>
  );
};

export default Select;
