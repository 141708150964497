import React, { useState, useEffect } from "react";

//helpers
import { useMutation, useQuery } from "react-query";
import { getRates, compareRates } from "../../services";
import { toast } from "react-toastify";
import styles from "./Convert.module.scss";

//components
import NavBar from "../../components/NavBar/NavBar";
import Conversions from "../../components/Conversions/Conversions";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import GetNotified from "../../components/GetNotified/GetNotified";
import Select from "../../components/SelectMain/Select";
import Success from "../../components/Success/Success";

//flags
import NigeriaFlag from "../../assets/flags/Nigeria.svg";
import CanadaFlag from "../../assets/flags/Canada.svg";
import ChinaFlag from "../../assets/flags/China.svg";
import GermanyFlag from "../../assets/flags/Germany.svg";
import UKFlag from "../../assets/flags/uk.svg";
import USAFlag from "../../assets/flags/usa.svg";
import BrazilFlag from "../../assets/flags/brazil.svg";

//icons
import ArrowRight from "../../assets/icons/switch-vertical.svg";
import BellIcon from "../../assets/icons/bell.svg";

const getCurrencyName = (currency) => {
  if(currency == "NGN") return "Naira";
  if(currency == "USD") return "Dollar";
  if(currency == "GBP") return "Pounds";
  if(currency == "CAD") return "Canadian Dollars";
  return currency;
  
};
const Convert = () => {
  const [showGetNotified, setShowGetNotified] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [bestRate, setBestRate] = useState();
  const [amount, setAmount] = useState();
  const [amountWithComma, setAmountWithComma] = useState();
  const selectOptions = [
    "NGN",
    "GBP",
    "CAD",
    "USD",
    "USDT",
    "USDC",
    "KES",
    "GHS",
    "ZAR",
  ];
  const [from, setFrom] = useState(selectOptions[0]);
  const [to, setTo] = useState(selectOptions[1]);
  const [matches, setMatches] = useState([]);

  // const handleSetRates = (data) => {
  //   let _rates = [];

  //   data?.forEach((platform) => {
  //     const entries = Object.entries(platform?.data);
  //     entries?.forEach(([ratePair, value]) =>
  //       _rates?.push({
  //         ratePair,
  //         value,
  //         platform: platform?.name,
  //         logo: platform?.logo,
  //       })
  //     );
  //   });
  //   setRates(_rates);
  // };

  const { isLoading, mutate } = useMutation(["compare rates"], compareRates, {
    onSuccess: (data) => {
      //console.log(data, "compare rates");
      setMatches(data?.data);
      const array = data?.data?.map((item) => item?.rate);
      let minIndex = array.reduce(
        (index, value, i, array) => (value > array[index] ? i : index),
        0
      );

      setBestRate(data?.data[minIndex]);
    },
    onError: (error) => {
      console.log({ error });

      toast.error(error?.response?.data?.message ?? "An error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    },
  });

  useEffect(() => {
     document.title = `Cheapest ${getCurrencyName(from)} to ${getCurrencyName(to)} exchange rates | FXRates Africa `;
    if (from && to) {
      mutate({
        from_currency: from,
        to_currency: to,
      });
    }
  }, [from, to]);

  const handleSwitch = (_from, _to) => {
    setFrom(_to);
    setTo(_from);
  };

  const handleAmountChange = (_amount) => {
    const _amountFormatted = _amount.replace(/\,/g, "");
    if (isNaN(Number(_amountFormatted))) {
      return;
    }
    setAmountWithComma(Number(_amountFormatted)?.toLocaleString());
    setAmount(Number(_amountFormatted));
  };
  return (
    <div className={styles.convert}>
      <NavBar active="convert" />

      <div className={styles.convert__intro}>
        <h1 className={styles.convert__intro__heading}>
          Get the best conversion when sending money home
        </h1>
        <div className={styles.convert__intro__nations}>
          <img src={CanadaFlag} alt="nation" />
          <img src={NigeriaFlag} alt="nation" />
          <img src={UKFlag} alt="nation" />
          <img src={USAFlag} alt="nation" />
          <img src={GermanyFlag} alt="nation" />
          <img src={ChinaFlag} alt="nation" />
          <img src={BrazilFlag} alt="nation" />
          <div className={styles.convert__intro__nations__more}>
            <p>More</p>
          </div>
        </div>
        <p className={styles.convert__intro__desc}>
          Get the best conversion when sending money home
        </p>
      </div>

      <div className={styles.convert__rate}>
        <div className={styles.convert__rate__to}>
          <h6>From</h6>

          <div className={styles.convert__rate__to__box}>
            <Select
              options={selectOptions}
              active={from}
              setActive={setFrom}
              style={{ background: "transparent" }}
            />
            <div className={styles.convert__rate__to__box__line}></div>
            <input
              type="tel"
              maxLength={12}
              value={amountWithComma}
              onChange={(e) => handleAmountChange(e?.target?.value)}
            />
          </div>
        </div>
        <div className={styles.convert__rate__arrow}>
          <h6>Convert</h6>
          <img
            onClick={() => handleSwitch(from, to)}
            src={ArrowRight}
            alt="arrow right"
          />
        </div>
        <div className={styles.convert__rate__from}>
          <h6>To</h6>
          <Select options={selectOptions} active={to} setActive={setTo} />
        </div>
      </div>

      {Number(amount) > 0 && (
        <div className={styles.convert__result}>
          <div className={styles.convert__result__value}>
            {matches?.length > 0 ? (
              <h3>{`${Number(amount).toLocaleString()} ${from} = ${Number(
                (bestRate.rate * Number(amount)).toFixed(3)
              ).toLocaleString()} ${to}`}</h3>
            ) : (
              <h3 style={{ color: "tomato" }}>RATE NOT FOUND</h3>
            )}

            <p>
              {matches?.length > 0 &&
                `Best market exchange rate (from ${bestRate?.name}) few seconds ago`}
            </p>
          </div>

          <div
            onClick={() => setShowGetNotified(true)}
            className={styles.convert__result__notify}
          >
            <img src={BellIcon} alt="notify" />
            <p>Get Notification about New Rate</p>
          </div>
        </div>
      )}

      <div className={styles.convert__rates}>
        <Conversions
          matches={matches}
          amount={Number(amount)}
          from={from}
          to={to}
          isLoading={isLoading}
        />
      </div>

      <div className={styles.convert__disclaimer}>
        <p>
          Disclaimer: The information provided on this website is for
          informational purposes only and should not be considered financial
          advice. The exchange rates displayed are based on information that we
          have gathered from various financial institutions and other sources.
          We strive to ensure that the information is accurate and updated
          regularly, but we cannot guarantee the accuracy, reliability, or
          completeness of the data at all times. Exchange rates can fluctuate
          rapidly, and the rate that you receive from a financial institution
          may be different from what is displayed on our website. Before making
          any financial decisions or currency exchanges, we recommend that you
          consult with a financial advisor or directly with the financial
          institution. We are not liable for any losses or damages, direct or
          indirect, that you may incur as a result of using the information
          provided on this website
        </p>
      </div>

      {showGetNotified && (
        <ModalWrapper>
          <GetNotified
            initialFrom={from}
            initialTo={to}
            initialAmount={amount}
            setShowGetNotified={setShowGetNotified}
            setShowSuccess={setShowSuccess}
          />
        </ModalWrapper>
      )}

      {showSuccess && (
        <ModalWrapper>
          <Success setShowSuccess={setShowSuccess} />
        </ModalWrapper>
      )}
    </div>
  );
};

export default Convert;
