import React, { useState, useRef, useEffect } from "react";
import styles from "./Select.module.scss";

//helpers
import useOutOfFocus from "../../hooks/useOutOfFocus";
import getIcon from "../../helpers/getIcon";

//images and icons
import ArrowDown from "../../assets/icons/chevron-down.svg";

const Select = ({ style, options, active, setActive }) => {
  const [showOptions, setShowOptions] = useState(false);
  const selectRef = useRef(null);
  const { isOutOfFocus, setIsOutOfFocus } = useOutOfFocus(selectRef);
  useEffect(() => {
    if (isOutOfFocus) {
      setIsOutOfFocus(false);
      setShowOptions(false);
    }
  }, [isOutOfFocus, setShowOptions]);

  return (
    <div className={styles.select}>
      <div
        onClick={() => setShowOptions(!showOptions)}
        className={styles.select__selected}
        style={style}
      >
        <img
          className={styles.select__selected__icon}
          src={getIcon(active)}
          alt="icon"
        />
        <h4>{active}</h4>
        <img
          className={styles.select__selected__arrow}
          src={ArrowDown}
          alt="arrow down"
        />
      </div>
      {showOptions && !isOutOfFocus ? (
        <div ref={selectRef} className={styles.select__options}>
          {options.map((option, index) => (
            <div
              onClick={() => {
                setActive(option);
                setShowOptions(false);
              }}
              key={index}
              className={styles.select__option}
            >
              <img
                className={styles.select__option__icon}
                src={getIcon(option)}
                alt="icon"
              />
              <p>{option}</p>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Select;
