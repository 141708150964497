import NigeriaFlag from "../assets/flags/Nigeria.svg";
import CanadaFlag from "../assets/flags/Canada.svg";
import BitcoinIcon from "../assets/icons/bitcoin.svg";
import USDTIcon from "../assets/icons/usdt.svg";
import ZelleIcon from "../assets/icons/zelle.svg";
import UKFlag from "../assets/flags/uk.svg";
import USAFlag from "../assets/flags/usa.svg";
import KESFlag from "../assets/flags/Kenya (KE).svg";
import GHFlag from "../assets/flags/Ghana (GH).svg";
import EuropeFlag from "../assets/flags/Europe.svg";
import USDCIcon from "../assets/icons/usdc.png";
import SouthAfricaFlag from "../assets/flags/South Africa (ZA).svg";

export default function getIcon(name) {
  switch (name) {
    case "GBP":
      return UKFlag;
    case "CAD":
      return CanadaFlag;
    case "USD":
      return USAFlag;
    case "BTC":
      return BitcoinIcon;
    case "USDT":
      return USDTIcon;
    case "USDC":
      return USDCIcon;
    case "KES":
      return KESFlag;
    case "GHS":
      return GHFlag;
    case "EUR":
      return EuropeFlag;
    case "NGN":
      return NigeriaFlag;
    case "ZAR":
      return SouthAfricaFlag;
    default:
      return NigeriaFlag;
  }
}
