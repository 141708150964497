import React, { useEffect } from "react";
import styles from "./Conversion.module.scss";

//components
import Platform from "../Platform/Platform";

const Conversion = ({ rate, from, to, amount }) => {
  const colors = [
    "#F0B90B",
    "#0898A0",
    "#163300",
    "#000",
    "#BA8F09",
    "#05638A",
    "#1EC677",
    "#1E1E1E",
  ];
  useEffect(() => {
    console.log(!["GBP", "USD", "USDT", "USDC"].includes(from));
  }, [from]);

  return (
    <div className={styles.conversion}>
      <div className={styles.conversion__platform}>
        <Platform
          rate={{
            ...rate,
            color: colors[Math.floor(Math.random() * colors?.length)],
          }}
        />
      </div>
      <div className={styles.conversion__rate}>
        <p>
          <h4>
            {!["GBP", "USD", "USDT", "USDC"].includes(from)
              ? `${Number(
                  (1 / rate?.rate).toFixed(3)
                ).toLocaleString()} ${from}`
              : `${Number(rate?.rate).toLocaleString()} ${to}`}
          </h4>
        </p>
      </div>

      <div className={styles.conversion__receiverRate}>
        <div className={styles.conversion__receiverRate__main}>
          <h4>
            {Number((rate?.rate * amount).toFixed(1)).toLocaleString()} {to}
          </h4>
          {/* <h6>Best rate -0.045</h6> */}
        </div>
      </div>
    </div>
  );
};

export default Conversion;
