import { Http } from "../utils/http";

export const getRates = () => Http.get(`/`);

export const getCryptoRates = () => Http.get(`/rates/crypto`);

export const getCentalBanksRate = () => Http.get(`/rates/central-banks`);

export const compareRates = (payload) => Http.post(`/rates/compare`, payload);

export const getNotified = (payload) => Http.post(`/notifications`, payload);
