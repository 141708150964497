import React, { useState, useEffect } from "react";
import styles from "./Platform.module.scss";

//images
import Binance from "../../assets/platforms/Binance.svg";
import Rise from "../../assets/platforms/rise.svg";
import Wise from "../../assets/platforms/Wise.svg";
import SendWave from "../../assets/platforms/sendwave.svg";

//helpers
import getLogoAndTag from "../../helpers/getLogoAndTag";

const Platform = ({ rate }) => {
  return (
    <div className={styles.platform}>
      <div
        style={{
          background:
            // getLogoAndTag(rate?.platform)?.color ??
            rate?.color,
        }}
        className={styles.platform__tag}
      >
        <p>{rate?.tag ?? "finance"}</p>
      </div>
      <img
        src={getLogoAndTag(rate?.platform)?.logo ?? rate?.logo}
        alt="provider"
      />
    </div>
  );
};

export default Platform;
