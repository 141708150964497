import Binance from "../assets/platforms/Binance.svg";
import Rise from "../assets/platforms/rise.svg";
import Wise from "../assets/platforms/Wise.svg";
import LemonadeFinance from "../assets/platforms/lemonade-finance.svg";
import Revolut from "../assets/platforms/Revolut.svg";
import TransferGo from "../assets/platforms/transfergo.svg";
import Sendwave from "../assets/platforms/sendwave.svg";
import Nala from "../assets/platforms/nala.svg";
import YellowCard from "../assets/platforms/yellow-card.svg";

export default function getLogoAndTag(name) {
  switch (name) {
    case "Binance P2P":
      return {
        logo: Binance,
        tag: "Crypto",
        color: "#F0B90B",
      };
    case "Wise":
      return {
        logo: Wise,
        tag: "Payment",
        color: "#163300",
      };
    /** case "Lemonade":
      return {
        logo: LemonadeFinance,
        tag: "Remittance",
        color: "#1EC677",
      }; 
    case "TransferGo":
      return {
        logo: TransferGo,
        tag: "Remittance",
        color: "#1E1E1E",
      };
    case "RiseVest":
      return {
        logo: Rise,
        tag: "investment",
        color: "#0898A0",
      };**/
    case "Revolut":
      return {
        logo: Revolut,
        tag: "Payment",
        color: "#1EC677",
      };
    /** case "Sendwave":
      return {
        logo: Sendwave,
        tag: "Remittance",
        color: "#1E1E1E",
      };
    case "Nala":
      return {
        logo: Nala,
        tag: "Remittance",
        color: "#05638A",
      };
    case "YellowCard":
      return {
        logo: YellowCard,
        tag: "Remittance",
        color: "#BA8F09",
      };**/
    default:
      return {
        tag: "Finance",
        color: "#0898A0",
      };
  }
}
