import React, { useState, useEffect } from "react";

//helpers
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { getCentalBanksRate } from "../../services";
import { toast } from "react-toastify";
import styles from "./CentralBanks.module.scss";

//components
import NavBar from "../../components/NavBar/NavBar";
import Rates from "../../components/Rates/Rates";
import Pagination from "../../components/Pagination/Pagination";

//flags
import NigeriaFlag from "../../assets/flags/Nigeria.svg";
import CanadaFlag from "../../assets/flags/Canada.svg";
import ChinaFlag from "../../assets/flags/China.svg";
import GermanyFlag from "../../assets/flags/Germany.svg";
import UKFlag from "../../assets/flags/uk.svg";
import USAFlag from "../../assets/flags/usa.svg";
import BrazilFlag from "../../assets/flags/brazil.svg";

const CentralBanks = () => {
  const navigate = useNavigate();
  const [rates, setRates] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const colors = [
    "#F0B90B",
    "#0898A0",
    "#163300",
    "#000",
    "#BA8F09",
    "#05638A",
    "#1EC677",
    "#1E1E1E",
  ];

  const { _page } = useParams();

  const handleSetRates = (data) => {
    let _rates = [];

    data?.forEach((platform) => {
      const entries = Object.entries(platform?.data);
      const color = colors[Math.floor(Math.random() * colors?.length)];
      entries?.forEach(([ratePair, value]) =>
        _rates?.push({
          ratePair,
          value,
          platform: platform?.name,
          logo: platform?.logo,
          tag: platform?.tag,
          color,
        })
      );
    });
    setRates(_rates);

    //set total page
    const _totalPage = _rates?.length > 0 ? Math.ceil(_rates?.length / 10) : 1;

    if (page > _totalPage) {
      setPage(_totalPage);
      setTotalPage(_totalPage);
      return;
    }

    setTotalPage(_totalPage);
  };

  const getPage = (rates) => {
    if (!rates || rates?.length < 1) {
      return [];
    }
    let startPoint = page * 10 - 10;
    let endpoint = page * 10;

    if (page === totalPage) {
      return rates?.slice(startPoint, rates?.length);
    } else {
      return rates?.slice(startPoint, endpoint);
    }
  };

  useEffect(() => {
    document.title = "Central bank exchange rates today | FXRates Africa"
    if (_page) {
      if (isNaN(_page) || Number(_page) === 0) {
        navigate("/central-banks");
        return;
      }
      setPage(Number(_page));
    }
  }, [_page, navigate]);

  const { isLoading } = useQuery(["get central banks rates"], getCentalBanksRate, {
    onSuccess: (data) => {
      console.log(data);
      handleSetRates(data?.data);
    },
    onError: (error) => {
      console.log({ error });

      toast.error(error?.response?.data?.message ?? "An error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    },
  });

  return (
    <div className={styles.centralBanks}>
      <NavBar active="central-banks" />

      <div className={styles.centralBanks__intro}>
        <h1 className={styles.centralBanks__intro__heading}>
          Get the best rates when sending money home
        </h1>
        <div className={styles.centralBanks__intro__nations}>
          <img src={CanadaFlag} alt="nation" />
          <img src={NigeriaFlag} alt="nation" />
          <img src={UKFlag} alt="nation" />
          <img src={USAFlag} alt="nation" />
          <img src={GermanyFlag} alt="nation" />
          <img src={ChinaFlag} alt="nation" />
          <img src={BrazilFlag} alt="nation" />
          <div className={styles.centralBanks__intro__nations__more}>
            <p>More</p>
          </div>
        </div>
        <p className={styles.centralBanks__intro__desc}>
          Easily get and compare exchange rates across remittance platforms
          before you send money centralBanks
        </p>
      </div>
      <div className={styles.centralBanks__rates}>
        <Rates rates={getPage(rates)} isLoading={isLoading} />
      </div>

      <div className={styles.centralBanks__pagination}>
        {totalPage > 1 && <Pagination page={page} base="central-banks/pages" totalPage={totalPage} />}
      </div>

      <div className={styles.centralBanks__disclaimer}>
        <p>
          Disclaimer: The information provided on this website is for
          informational purposes only and should not be considered financial
          advice. The exchange rates displayed are based on information that we
          have gathered from various financial institutions and other sources.
          We strive to ensure that the information is accurate and updated
          regularly, but we cannot guarantee the accuracy, reliability, or
          completeness of the data at all times. Exchange rates can fluctuate
          rapidly, and the rate that you receive from a financial institution
          may be different from what is displayed on our website. Before making
          any financial decisions or currency exchanges, we recommend that you
          consult with a financial advisor or directly with the financial
          institution. We are not liable for any losses or damages, direct or
          indirect, that you may incur as a result of using the information
          provided on this website
        </p>
      </div>
    </div>
  );
};

export default CentralBanks;
