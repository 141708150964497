import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { getNotified } from "../../services";
import styles from "./GetNotified.module.scss";
import ReactLoading from "react-loading";
//icons
import XIcon from "../../assets/icons/xmark.svg";
import ArrowRight from "../../assets/icons/arrow-sm-right.svg";

//components
import Select from "../Select/Select";

const GetNotified = ({
  setShowGetNotified,
  setShowSuccess,
  initialFrom,
  initialTo,
  initialAmount,
}) => {
  const [getDailyUpdate, setGetDailyUpdate] = useState(false);
  const [email, setEmail] = useState();
  const [amount, setAmount] = useState(initialAmount ?? "");
  const selectOptions = [
    "NGN",
    "GBP",
    "CAD",
    "USD",
    "USDT",
    "USDC",
    "KES",
    "GHS",
  ];
  const [from, setFrom] = useState(initialFrom ?? selectOptions[0]);
  const [to, setTo] = useState(initialTo ?? selectOptions[1]);
  const [direction, setDirection] = useState("Goes above rate");
  const [amountWithComma, setAmountWithComma] = useState();

  const { isLoading, mutate } = useMutation(["compare rates"], getNotified, {
    onSuccess: (data) => {
      setShowGetNotified(false)
      setShowSuccess(true);
    },
    onError: (error) => {
      console.log({ error });

      toast.error(error?.response?.data?.message ?? "An error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    },
  });

  const handleSubmit = () => {
    console.log("called");
    if (!email) {
      console.log("no email");
      toast.error("Email address is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    if (!amount) {
      toast.error("Trigger rate is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    mutate({
      fromCurrency: from,
      toCurrency: to,
      direction: "ABOVE",
      email,
      triggerRate: amount,
      dailyUpdate: getDailyUpdate,
    });
  };

  const handleAmountChange = (_amount) => {
    const _amountFormatted = _amount?.toString().replace(/\,/g, "");
    if (isNaN(Number(_amountFormatted))) {
      return;
    }
    setAmountWithComma(Number(_amountFormatted)?.toLocaleString());
    setAmount(Number(_amountFormatted));
  };

  useEffect(() => {
    if (initialAmount) {
      handleAmountChange(initialAmount);
    }
  }, [initialAmount]);

  return (
    <div className={styles.getNotified}>
      <div
        onClick={() => setShowGetNotified(false)}
        className={styles.getNotified__cancel}
      >
        <img src={XIcon} alt="cancel icon" />
      </div>

      <div className={styles.getNotified__main}>
        <h3 className={styles.getNotified__main__heading}>
          Waiting on a better Rate?
        </h3>
        <p className={styles.getNotified__main__desc}>
          Set an alert now, and we will tell you when it gets better. And with
          our daily summaries, you will never miss out on the latest news.
        </p>

        <div className={styles.getNotified__main__options}>
          <div className={styles.getNotified__main__option}>
            <div>
              <h4>Daily Update</h4>
              <p>Receive daily email about the USD to dollar Exchange rate</p>
            </div>
            <div
              onClick={() => setGetDailyUpdate(!getDailyUpdate)}
              className={
                getDailyUpdate
                  ? `${styles.getNotified__main__option__checkbox} ${styles.getNotified__main__option__checkbox__checked}`
                  : `${styles.getNotified__main__option__checkbox}`
              }
            ></div>
          </div>
          {/* <div className={styles.getNotified__main__option}>
            <div>
              <h4>Email me when</h4>
              <p>Receive daily email about the USD to dollar Exchange rate</p>
            </div>
            <div className={styles.getNotified__main__option__checkbox}></div>
          </div> */}
        </div>
        {/* <div>
          <label>Direction</label>
          <Select
            options={["Goes above rate", "Goes below rate"]}
            setActive={setDirection}
            active={direction}
            // style={{ background: "transparent" }}
          />
        </div> */}
        <div className={styles.getNotified__main__rate}>
          <div className={styles.getNotified__main__rate__from}>
            <h6>I {from}</h6>
            <Select options={selectOptions} setActive={setFrom} active={from} />
          </div>

          <img
            className={styles.getNotified__main__rate__arrow}
            src={ArrowRight}
            alt="arrow right"
          />

          <div className={styles.getNotified__main__rate__to}>
            <h6>Goes above</h6>

            <div className={styles.getNotified__main__rate__to__box}>
              <Select
                options={selectOptions}
                setActive={setTo}
                active={to}
                style={{ background: "transparent" }}
              />
              <div
                className={styles.getNotified__main__rate__to__box__line}
              ></div>
              <input
                type="tel"
                value={amountWithComma}
                onChange={(e) => handleAmountChange(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className={styles.getNotified__main__email}>
          <h3>Email Address</h3>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email your email Address"
          />
        </div>

        <button
          onClick={handleSubmit}
          className={styles.getNotified__main__submit}
        >
          {isLoading ? (
            <ReactLoading type="spin" width={32} height={32} />
          ) : (
            `Get Rate Alerts`
          )}
        </button>
      </div>
    </div>
  );
};

export default GetNotified;
