import React from "react";
import styles from "./Rate.module.scss";

//components
import Select from "../SelectVariant/Select";
import Platform from "../Platform/Platform";

//platforms
import Binance from "../../assets/platforms/Binance.png";
import Rise from "../../assets/platforms/rise.svg";

//icons
import ArrowRight from "../../assets/icons/arrow-sm-right.svg";

const Rate = ({ rate }) => {
  const [from, to] = rate?.ratePair?.split("_");

  return (
    <div className={styles.rate}>
      <div className={styles.rate__platform}>
        <Platform rate={rate} />
      </div>
      <div className={styles.rate__currencyPair}>
        <div className={styles.rate__currencyPair__from}>
          {/* <h6>From</h6> */}
          <Select label={from} />
        </div>
        <img
          className={styles.rate__currencyPair__arrow}
          src={ArrowRight}
          alt="arrow right"
        />
        <div className={styles.rate__currencyPair__to}>
          {/* <h6>To</h6> */}
          <Select label={to} />
        </div>
      </div>

      <div className={styles.rate__rate}>
        <div className={styles.rate__rate__main}>
          <h4>
            {!["GBP", "USD", "USDT", "USDC", "CAD"].includes(from)
              ? Number((1 / rate?.value).toFixed(2)).toLocaleString()
              : Number(rate?.value)?.toLocaleString()}
          </h4>
          {/* <h6>Best Rate -0.000</h6> */}
        </div>
      </div>
    </div>
  );
};

export default Rate;
