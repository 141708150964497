import React from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";

// pages
import Home from "../pages/Home/Home";
import Convert from "../pages/Convert/Convert";
import Providers from "../pages/Providers/Providers";
import Crypto from "../pages/Crypto/Crypto";
import CentralBanks from "../pages/CentralBanks/CentralBanks";

const Routes = () => {
  return (
    <>
      <Switch>
        <Route path="/" element={<Home />} />
        <Route path="/crypto" element={<Crypto />} />
        <Route path="/crypto/pages/:_page" element={<Crypto />} />
        <Route path="/central-banks" element={<CentralBanks />} />
        <Route path="/central-banks/pages/:_page" element={<CentralBanks />} />
        <Route path="/pages/:_page" element={<Home />} />
        <Route path="/convert" element={<Convert />} />
        <Route path="/providers" element={<Providers />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Switch>
    </>
  );
};

export default Routes;
